import * as React from "react";
import { Widget } from "@typeform/embed-react";

const EarlyBirdPage = () => {
  return (
    <Widget id="S2zw6kXR" style={{ height: "100vh" }} className="my-form" />
  );
};

export default EarlyBirdPage;
